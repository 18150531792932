<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h1 class="m-0 text-dark"><b>Rider: {{ riderDetails }}, Total Parcel: {{ totalRiderAssigned }}</b> </h1>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-warning btn-sm mr-2" @click="pdfDownload">PDF <i class="fa fa-download"></i></button>
                                            <a :href="$baseUrl + 'excel-download/assigned-order?rider_id='+ this.$route.query.rider_id +'&status='+ this.$route.query.status + '&date='+ this.$route.query.date" class="ml-1 btn btn-success btn-sm mr-2">Excel <i class="fa fa-download"></i></a>
                                            <!-- <button v-if="$route.query.status == 7" class="btn btn-info btn-sm"  @click="onWay($route.query.rider_id)" title="On Way">On Way <i class="fa fa-truck" aria-hidden="true"></i></button>     -->
                                            <button v-if="isRiderAssigned" class="btn btn-info btn-sm"  @click="onWay($route.query.rider_id)" title="On Way">On Way <i class="fa fa-truck" aria-hidden="true"></i></button>    
                                            
                                            <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <b-overlay :show="loader">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Delivered</th>
                                                        <th>:</th>
                                                        <th>{{ summary.delivered }}</th>
                                                        <th>Rescheduled</th>
                                                        <th>:</th>
                                                        <th>{{ summary.rescheduled }}</th>
                                                        <th>Cancel</th>
                                                        <th>:</th>
                                                        <th>{{ summary.canceled }}</th>
                                                    </tr> 
                                                    <tr>
                                                        <th>Partial Delivered</th>
                                                        <th>:</th>
                                                        <th>{{ summary.partialDelivered }}</th>
                                                        <th>Exchanged</th>
                                                        <th>:</th>
                                                        <th>{{ summary.exchanged }}</th>
                                                        <th>Hold</th>
                                                        <th>:</th>
                                                        <th>{{ summary.hold }}</th>
                                                    </tr> 
                                                    <tr>
                                                        <th>Total Parcel</th>
                                                        <th>:</th>
                                                        <th>{{ summary.totalParcel }}</th>                                                    
                                                        <th>Total Collected</th>
                                                        <th>:</th>
                                                        <th colspan="2">{{ summary.totalCollected }}</th>                                                    
                                                        <th colspan="2">
                                                            <button v-if="checked" class="btn btn-info" title="Submit" @click="receive">Receive</button>    
                                                        </th>                                                    
                                                    </tr> 
                                                </thead>
                                            </table>
                                        </b-overlay>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-sm text-center">
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Order ID</th>
                                                    <th>Order Date</th>
                                                    <th>Customer</th>
                                                    <th>Address</th>
                                                    <th>Instruction</th>
                                                    <th>Ex.</th>
                                                    <th>Price</th>
                                                    <th>Collected</th>
                                                    <th>OTP</th>
                                                    <th>Status</th>
                                                    <th>Reason</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item,key) in orders" :key="key" :class="item.amount >= item.collected ? 'text-red' : 'text-black'">
                                                    <td>{{ key + 1 }}</td>
                                                    <td>{{ item.id }}</td>
                                                    <td @click="orderLog(item.logs)">{{ item.date | dateformat }}</td>
                                                    <td>{{ item.name }}<br/>{{ item.mobile }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td>{{ item.instruction }}</td>
                                                    <td>{{ item.exchange == 1 ? 'Ex.' : '-' }}</td>
                                                    <td>{{ item.price }}</td>
                                                    <td>
                                                        <input type="text" class="w-70" @input="changeCollected(key, item)" v-model="item.collected" :class="(item.status == 15 && item.collected == 0) ? 'border-red' : ''" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>                                                         
                                                    </td>   
                                                    <td>{{ (item.status == 12 || item.status == 20) ? item.otp : '' }}</td>    
                                                    <td>
                                                        <select @change="changeStatus(key, item)" v-model="item.status">
                                                            <option v-for="(status, indx) in statusList" :value="status.id" :key="indx" :selected="item.status == status.id">{{ status.text }}</option>
                                                        </select><br/>    
                                                        <input v-if="item.status == 18" type="date" v-model="item.reschedule_date" :class="!item.reschedule_date ? 'border-red' : ''"/>                                                                                                     
                                                    </td>                                              
                                                    <td>
                                                        <input type="text" v-model="item.reason" @input="changeReason(key, item)" :class="item.is_reason ? 'border-red' : ''" class="w-100-p" placeholder="Maximum 200 character"/>                                                        
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <button v-if="!checked" class="btn btn-primary float-right mr-2" title="Submit" @click="checkSummary">Check Summary</button>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:30%">Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config' 
    import ExportPdf from './AssignedOrder'
    export default {
        name:'AssignedOrderDetails',
        data(){
            return {   
                orderLogModal:false,
                order_logs:[],
                loader: false,             
                riderDetails: null,             
                orders:[],
                totalRiderAssigned: 0,
                summary: {
                    delivered: 0,
                    partialDelivered: 0,
                    hold: 0,
                    exchanged: 0,
                    rescheduled: 0,
                    canceled: 0,
                    totalParcel: 0,
                    totalCollected: 0,
                },
                search: {
                    rider_id: '',
                    status: '',
                    hub_id: '',
                    date: ''
                },
                isRiderAssigned: false,
                merchant: '',
                totalValue: 0,
                totalCheck: 0,
                checked: 0,
                statusList: [
                    { id: 7, text: 'Rider Assigned' },
                    { id: 11, text: 'On Way' },
                    { id: 14, text: 'Delivered' },
                    { id: 12, text: 'Cancel Request' },
                    { id: 13, text: 'Partial Request' },
                    { id: 15, text: 'Partial Delivered' },
                    { id: 19, text: 'Exchanged' },
                    { id: 20, text: 'Cancel' },
                    { id: 17, text: 'Hold' },
                    { id: 18, text: 'Reschedule' },
                ]
            }
        },
        created(){
            this.search.rider_id = this.$route.query.rider_id
            this.search.status = this.$route.query.status
            this.search.date = this.$route.query.date
            this.search.hub_id = this.$route.query.hub_id
            this.getRiderDetails(this.$route.query.rider_id)
            this.getAssignedOrderDetails()
            // this.getAssignedOrderDetailsSummary()
        },
        computed: {     
            reasonList: function () {
                return this.$store.state.commonObj.reasonList
            },
            hubList () {
                return this.$store.state.commonObj.hubList
            },
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            getRiderDetails (riderId) {
                const rider = this.$store.state.commonObj.riderList.find(el => el.id == riderId)
                this.riderDetails = rider ? (rider.text + ', '+ rider.mobile) : ''
            },
            getAssignedOrderDetailsSummary() {
                this.loader = true
                const params = this.search
                config.getData('/hub/rider-assigned/details-summary', params)
                .then((response) => {
                    this.loader = false
                    this.summary = response.success ? response.summary : null
                });
            },
            getAssignedOrderDetails() {
                this.loader = true
                const params = this.search
                config.getData('/hub/rider-assigned/details-list', params)
                .then((response) => {
                    this.loader = false
                    if (response.success){
                        this.totalRiderAssigned = response.data.length
                        this.totalValue = response.total
                        this.isRiderAssigned = response.rider_assigned > 0 ? true : false
                        this.orders = this.getRelationalData(response.data)
                    } else {
                        this.isRiderAssigned = false
                        this.totalValue = 0
                        this.orders = []
                    } 
                });
            },
            getRelationalData (data) {
                return data.map(item => {
                    return Object.assign(item, { is_reason: 0, is_collected: 0 })
                })
            },
            pdfDownload() {
                const hub = this.hubList.find(el => el.id == this.authUser.hub_id)
                const hubName = hub ? hub.text : ''
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.returnPdfDetails(base64Logo, this.orders, this.riderDetails, this.totalValue, hubName)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return tmpStatus.text
            },
            getReason (reasonId) {
                const tmpReason = typeof reasonId !== 'undefined' ? this.$store.state.reasonList.find(tmp => tmp.id == reasonId) : ''
                return tmpReason ? tmpReason.text : ''
            },
            orderLog (logs) {
                this.order_logs = logs 
                this.orderLogModal = true
            },
            cancelModal () {
                this.orderLogModal = false
            },
            changeCollected (key, item) {
                item.is_collected = item.status == 15 ? 0 : item.is_collected
                item.is_reason = item.price != item.collected ? 1 : 0
                this.checked = 0
            },
            changeReason (key, item) {
                if(item.reason.length > 255) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Reason allowed maximum 255 character',
                        color: '#dc3545'
                    })
                }
                item.is_reason = 0
            },
            changeStatus (key, item) {  
                this.checked = 0           
                if (item.status == 14) { // delivered
                    item.collected = item.price
                    item.payment_status = 1
                    item.is_reason = 0
                } else if (item.status == 15) { // partial delivered
                    // item.collected = 0
                    item.is_collected = 1
                    item.payment_status = 1
                    item.is_reason = 1
                } else if (item.status == 17) { // hold
                    // item.collected = 0
                    item.payment_status = 0
                    item.is_reason = 1
                } else if (item.status == 18) { // Reschedule
                    // item.collected = 0
                    item.payment_status = 0
                    item.is_reason = 1
                } else if (item.status == 19 ||item.status == 20) { // 19=Exchange, 20=Cancel
                    // item.collected = 0
                    item.is_reason = 1
                }
            },
            checkSummary () {                    
                Object.assign(this.summary, {
                    delivered: 0,
                    partialDelivered: 0,
                    hold: 0,
                    exchanged: 0,
                    rescheduled: 0,
                    canceled: 0,
                    totalCollected: 0,
                })
                    
                this.orders.map(item => {
                    if (item.status == 14) {
                        this.summary.delivered += 1
                    } else if (item.status == 15) {
                        this.summary.partialDelivered += 1
                    } else if (item.status == 17) {
                        item.payment_status = 1
                        this.summary.hold += 1
                    } else if (item.status == 18) {
                        this.summary.rescheduled += 1
                    } else if (item.status == 19) {
                        this.summary.exchanged += 1
                    } else if (item.status == 20) {
                        this.summary.canceled += 1
                    }
                    this.totalCheck = (item.status == 7 || item.status == 11 || item.is_reason == 1 || (item.status == 15 && item.collected == 0)) ? 1 : 0
                    this.summary.totalCollected += parseFloat(item.collected)
                })
                this.summary.totalParcel = parseInt(this.summary.delivered + this.summary.partialDelivered + this.summary.hold + this.summary.rescheduled + this.summary.exchanged + this.summary.canceled)
                this.checked = 1                
                if (this.totalCheck == 1) {
                    this.checked = 0
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please add reason or update all parcel',
                        color: '#dc3545'
                    })
                }
            },
            async receive () {
                this.loader = true
                const formData = { orders: this.orders, collection: this.summary.totalCollected, hub_id: this.$route.query.hub_id }
                const response = await config.postData('/hub/rider-assigned/collection-receive', formData)
                this.loader = false
                if (response.success) {
                    this.$router.push('/hub-rider-assigned-order')
                } else {
                    this.summary = null
                }
            },
            async onWay(riderId){
                this.loader = true
                const params = { rider_id: riderId, activity_date: this.$route.query.date }
                const response = await config.postData(`/hub/rider-assigned/on-way`, params)
                this.loader = false
                if (response.success) {
                    this.$router.push('/hub-rider-assigned-order')
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {                
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }            
            },
        }
    }
</script>
<style scoped>
    .border-red {
        border: red 2px solid;
    }
    .w-60 {
        width: 60px !important;
    }
    .w-70 {
        width: 70px !important;
    }
    .w-100-p {
        width: 100% !important;
    }
</style>